import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import Loader from '../components/loader';
import Error from '../components/error';
//import API from '../../jsx/backend';
import License from '../../jsx/license';
import Top from '../hooks/topping';
import Greeting from '../hooks/greeting';
import { Link } from "react-router-dom";
import Cloud from '../../img/cloud.png';
import Bg from '../../img/bg.jpg';
import SearchDomain from '../components/searchDomain';
import ReadMore from '../components/readMore';

const Home = () => {
const [error, setError] = useState(null);
const [loading, setLoading] = useState(true);
const [title, setTitle] = useState('Please Wait');

useEffect(() => {
  const searchLicense = new FormData();
  searchLicense.append("license", "0");
  searchLicense.append("action", "action");
  License.post(`/`, searchLicense)
.then((res) => {
setTitle(res.data.title);
setLoading(false);
})
.catch((error) => {
setTitle('Not Responding');
setLoading(false);
setError(true);
})}, []);

return (
<div className="marmasco-main put-margin inner">
<Helmet>
<title>{ title } - Marmasco</title>
</Helmet>


{loading
? <div className="marmasco-section">
  <div className="marmasco-container">
  <Loader />
  </div>
  </div>
: <div className="marmasco-section">
{error
? <div className="marmasco-container">
  <Error />
  </div>
: <div style={{ marginTop: "-30px" }}>
<div  style={{
      backgroundImage: 'url(' + Bg + ')', backgroundRepeat: "no-repeat", backgroundPosition: "center center", backgroundSize: "100% 100%"
    }}>
<div className="marmasco-padding" style={{backgroundColor: "#ffffffe3 ", minHeight: "350px"}}>
<h1 className="marmasco-center"><Greeting />, GET A NICE DOMAIN!</h1>
<p className="marmasco-center">You need a domain to host your business website or emails, be unique, short and nice.</p>
<div style={{paddingTop: "60px"}}></div>
<div className="marmasco-section marmasco-container marmasco-content">
<SearchDomain />
</div>
<div style={{paddingTop: "20px"}}></div>
</div>
</div>
<div className="marmasco-section marmasco-content">
<div style={{paddingTop: "50px"}}></div>
<div className="marmasco-container marmasco-row-padding">
<div className="marmasco-half">
<div style={{paddingTop: "20px"}}></div>
<img src={ Cloud} alt="Cloud Computing" style={{width: "400px", height: "420px", maxWidth: "100%", cursor: "pointer"}} title="Instant Cloud" />
</div>
<div className="marmasco-half marmasco-justify">
<h4 className="marmasco-text-teal"><b>INSTANT CLOUD<i className="fas fa-cloud marmasco-right"></i></b></h4>
<p>We automated our cloud platform to deliver all services instantly with each confirmed payment. You do not have to worry about the boring long waiting hours for your account or cloud server (VPS) to be delivered.</p>
<p>All our servers use SSD storage which improves perfomance and reliability. We give you unlimited support plus root access and a dedicated IP address with each server you purchase from Marmasco. Shared hosting plans come with unlimited support plus 99.9% uptime guarantee.</p>
<p>You can resell all our services, that is, shared hosting, VPS hosting and domain registartion using your own company's branding. Marmasco resellers get amazing discounts everytime!</p>
<Link to="/auth/register/" className="marmasco-btn marmasco-teal" style={{borderRadius: "10px", width: "100%"}}>GET STARTED NOW <i className="fas fa-angle-right"></i></Link>
</div>
</div>
<div className="marmasco-container marmasco-row-padding" style={{paddingTop: "50px"}}>
<ReadMore />
</div>
</div>
</div>
}
</div>
}
<Top />
</div>
);}

export default Home;