import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import Loader from '../components/loader';
import Error from '../components/error';
import API from '../../jsx/backend';
import Top from '../hooks/topping';
import { Link, useParams } from "react-router-dom";
import SearchDomain from '../components/searchDomain';
import Bg from '../../img/bg.jpg';
import MoreExt from '../components/moreExt';

const Post = () => {
const [error, setError] = useState(null);
const [loading, setLoading] = useState(true);
const [title, setTitle] = useState('Please Wait');
const [content, setContent] = useState('No Content');
const params = useParams();

useEffect(() => {
API.get(`api/pages/post/${params.post}/`)
.then((res) => {
setTitle(res.data.title);
setContent(res.data.content);
setLoading(false);
})
.catch((error) => {
setTitle('Not Responding');
setLoading(false);
setError(true);
})}, [params.post]);

return (
<div className="marmasco-main put-margin inner">
<Helmet>
<title>{ title } - Marmasco</title>
</Helmet>

{loading
? <div className="marmasco-section">
  <div className="marmasco-container">
  <Loader />
  </div>
  </div>
: <div className="marmasco-section">
{error
? <div className="marmasco-container">
  <Error />
  </div>
: <>
<div className="marmasco-container marmasco-content">
<h4 className="marmasco-text-teal"><b>{ title }<Link to="/blog/" style={{textDecoration: "none"}}><i className="fas fa-blog marmasco-right"></i></Link></b></h4>
<hr />
<div className="marmasco-justify" dangerouslySetInnerHTML={{__html: content}}></div>
</div>
<br />
<div style={{
      backgroundImage: 'url(' + Bg + ')', backgroundRepeat: "no-repeat", backgroundPosition: "center center", backgroundSize: "100% 100%"
    }}>
<div className="marmasco-padding" style={{backgroundColor: "#ffffffe3"}}>
<div className="marmasco-container marmasco-content" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
<h4 className="marmasco-text-teal"><b><Link to="/get-domains/" style={{textDecoration: "none"}}>WOULD YOU LIKE TO GROW YOUR BUSINESS?</Link><Link to="/get-domains/" style={{textDecoration: "none"}}><i className="fas fa-globe marmasco-right"></i></Link></b></h4>
<div style={{ paddingTop: "30px" }}></div>
  <SearchDomain />
</div>
</div>
</div>
<div className="marmasco-container marmasco-content">
  <div style={{paddingTop: "20px"}}></div>
<MoreExt />
  </div>
  </>
}
  </div>
}
<Top />
</div>
);}

export default Post;