import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import Loader from '../components/loader';
import Error from '../components/error';
import API from '../../jsx/backend';
import Top from '../hooks/topping';
import SearchDomain from '../components/searchDomain';
import ReadMore from '../components/readMore';
import { Link } from 'react-router-dom';
import Bg from '../../img/bg.jpg';

const RemotePprices = () => {
const [error, setError] = useState(null);
const [loading, setLoading] = useState(true);
const [title, setTitle] = useState('Please Wait');
const [fees, setFees] = useState('');

useEffect(() => {
API.get(`api/pages/page/remote-computer/pricing/`)
.then((res) => {
setTitle(res.data.title);
API.get(`api/fees/remote-computer/`)
.then((res) => {
setFees(res.data.fee);
setLoading(false);
})
.catch((error) => {
setFees("");
setLoading(false);
setError(true);
})})
.catch((error) => {
setTitle('Not Responding');
setLoading(false);
setError(true);
})}, []);

return (
<div className="marmasco-main put-margin inner">
<Helmet>
<title>{ title } - Marmasco</title>
</Helmet>

{loading
? <div className="marmasco-section">
  <div className="marmasco-container">
  <Loader />
  </div>
  </div>
: <div className="marmasco-section">
{error
? <div className="marmasco-container">
  <Error />
  </div>
: <>
<div className="marmasco-container marmasco-content marmasco-justify marmasco-padding">
<h4 className="marmasco-text-teal"><b>REMOTE PC PRICES<i className="fas fa-tag marmasco-right"></i></b></h4>
<div style={{width: "100%", overflowX: "auto"}}>
<table class="marmasco-table marmasco-bordered" style={{border: "solid grey 1px" }}>
<tr className="marmasco-sand">
  <th>Package</th>
  <th>Storage</th>
  <th>Processor</th>
  <th>Memory</th>
  <th>System</th>
  <th>Control</th>
  <th>Monthly</th>
  <th>Yearly</th>
  <th>Subscribe</th>
</tr>
{fees
? <>
{fees.map((price) => (
<tr className="marmasco-hover-teal">
  <td><p>{ price.package}</p></td>
  <td><p>{ price.storage } { price.units}</p></td>
  <td><p>{ price.processor }</p></td>
  <td><p>{ price.memory } { price.units}</p></td>
  <td><p>{ price.type }</p></td>
  <td><p>{ price.control}</p></td>
  <td><p>{ price.monthly }  { price.currency}</p></td>
  <td><p>{ price.yearly }  { price.currency}</p></td>
  <td className="marmasco-white marmasco-hover-white marmasco-center"><Link to={`/cart/remote-computer/${price.reference }/`} style={{textDecoration: "none"}} title="GET COMPUTER"><button className="marmasco-btn marmasco-pale-green marmasco-xlarge  marmasco-opacity" style={{borderRadius: "10px"}}>BUY</button></Link></td>
</tr>
))}
</>
: <h4 className="marmasco-center" style={{paddingTop: "50px"}}>Empty!</h4>
}
</table>
</div>
</div>
<br />
<div style={{
      backgroundImage: 'url(' + Bg + ')', backgroundRepeat: "no-repeat", backgroundPosition: "center center", backgroundSize: "100% 100%"
    }}>
<div className="marmasco-padding" style={{backgroundColor: "#ffffffe3"}}>
<div className="marmasco-container marmasco-content" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
<h4 className="marmasco-text-teal"><b><Link to="/get-domains/" style={{textDecoration: "none"}}>WOULD YOU LIKE TO GROW YOUR BUSINESS?</Link><Link to="/get-domains/" style={{textDecoration: "none"}}><i className="fas fa-globe marmasco-right"></i></Link></b></h4>
<div style={{ paddingTop: "30px" }}></div>
  <SearchDomain />
</div>
</div>
</div>
<div className="marmasco-container marmasco-content" >
  <div style={{paddingTop: "20px"}}></div>
  <ReadMore />
  </div>
  </>
}
  </div>
}
<Top />
</div>
);}

export default RemotePprices;