import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import Loader from '../components/loader';
import Error from '../components/error';
import API from '../../jsx/backend';
import Top from '../hooks/topping';
import { Link } from "react-router-dom";
import InputHints from 'react-input-hints';

const Blog = () => {
const [error, setError] = useState(null);
const [loading, setLoading] = useState(true);
const [processing, setProcessing] = useState(false);
const [phrase, setPhrase] = useState('');
const [title, setTitle] = useState('Please Wait');
const [tip, setTips] = useState('');
const [results, setResults] = useState(false);

useEffect(() => {
API.get(`api/pages/page/blog/`)
.then((res) => {
setTitle(res.data.title);
API.get(`api/tips/blog/`)
.then((res) => {
setTips(res.data.tip);
setLoading(false);
})
.catch((error) => {
setTips("");
setLoading(false);
setError(true);
})})
.catch((error) => {
setTitle('Not Responding');
setLoading(false);
setError(true);
})}, []);

const forPhrase = e => {
  setPhrase(e.target.value);
}

const handleSearch = (event) => {
  event.preventDefault();
  setProcessing(true);
  setError(false);
  const searchFormDataNew = new FormData();
  searchFormDataNew.append("phrase", phrase);
  API.post(`api/tips/search/`, searchFormDataNew)
  .then((response) => {
  setTips(response.data.tip);
  setResults(true);
  setProcessing(false);
  })
  .catch((error) => {
  setError(true);
  setProcessing(false);
  })}

  const handleReset = (event) => {
    event.preventDefault();
    setProcessing(true);
    setError(false);
    setResults(false);
  API.get(`api/tips/blog/`)
  .then((res) => {
  setTips(res.data.tip);
  setProcessing(false);
  })
  .catch((error) => {
  setTips("");
  setProcessing(false);
  setError(true);
  })}

return (
<div className="marmasco-main put-margin inner">
<Helmet>
<title>{ title } - Marmasco</title>
</Helmet>

{loading
? <div className="marmasco-section">
  <div className="marmasco-container">
  <Loader />
  </div>
  </div>
: <div className="marmasco-section">
{error
? <div className="marmasco-container">
  <Error />
  </div>
: <>
<div className="marmasco-container marmasco-content marmasco-padding">
<h4 className="marmasco-text-teal"><b>WEB HOSTING & DOMAIN REGISTRATION BLOG<i className="fas fa-blog marmasco-right"></i></b></h4>
<div style={{ paddingTop: "20px" }}></div>
<form className="marmasco-white marmasco-container marmasco-padding marmasco-border marmasco-border-grey" style={{borderRadius: "10px"}} onSubmit={handleSearch}>
<div className="marmasco-col s7">
<InputHints type="text" value={phrase} onChange={forPhrase} className="marmasco-input marmasco-border-0" style={{borderRadius: "10px 0 0 10px", width: "100%", outline: "none"}} placeholders={ ['Type search phrase...', 'Install WordPress...', 'Configure server...', 'Setup email on Outlook...'] }  required/>
</div>
<div className="marmasco-col s5">
{results
?<button type="button" onClick={handleReset} className="marmasco-btn marmasco-brown marmasco-border-0" style={{borderRadius: "10px", width: "100%"}}><i className="fas fa-times"></i> <span className="marmasco-hide-small">RESET FILTER</span></button>
:<button type="submit" className="marmasco-btn marmasco-teal marmasco-border-0" style={{borderRadius: "10px", width: "100%"}}><i className="fas fa-search"></i> <span className="marmasco-hide-small">SEARCH ARTICLES</span></button>
}
</div>
</form>
<div style={{ paddingTop: "20px" }}></div>
</div>
<div className="marmasco-container marmasco-content marmasco-row-padding" style={{paddingBottom: "50px"}}>
{processing
? <Loader />
:<>
{tip
? <>
{tip.map((post) => (
<div className="marmasco-third" style={{borderRadius: "10px 10px 0 0"}}>
 <br />   
<Link to={`/blog/${post.url }/`} style={{textDecoration: "none"}} title={post.description }>
<div className="marmasco-card" style={{
      backgroundImage: 'url(' + post.image + ')',
      height: "auto", backgroundRepeat: "no-repeat", backgroundPosition: "center center", backgroundSize: "100% auto", borderRadius: "10px 10px 0 0"
    }}>
<div className="marmasco-padding" style={{backgroundColor: "#ffffffe3 ", minHeight: "350px", borderRadius: "10px"}}>
<h3 className="marmasco-center">{ post.title  }</h3>
</div>
<button className="marmasco-btn marmasco-teal" style={{width: "100%"}}>READ MORE</button>
</div>
</Link>
</div>
))}
</>
: <h4 className="marmasco-center" style={{paddingTop: "50px"}}>We have no post suggestions!</h4>
}
</>
}
</div>
</>
}
  </div>
}
<Top />
</div>
);}

export default Blog;