import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import Loader from '../components/loader';
import Error from '../components/error';
import API from '../../jsx/backend';
import Top from '../hooks/topping';
import SearchDomain from '../components/searchDomain';
import ReadMore from '../components/readMore';
import { Link } from 'react-router-dom';
import Bg from '../../img/bg.jpg';

const WebHosting = () => {
const [error, setError] = useState(null);
const [loading, setLoading] = useState(true);
const [title, setTitle] = useState('Please Wait');

useEffect(() => {
API.get(`api/pages/page/web-hosting/`)
.then((res) => {
setTitle(res.data.title);
setLoading(false);
})
.catch((error) => {
setTitle('Not Responding');
setLoading(false);
setError(true);
})}, []);

return (
<div className="marmasco-main put-margin inner">
<Helmet>
<title>{ title } - Marmasco</title>
</Helmet>

{loading
? <div className="marmasco-section">
  <div className="marmasco-container">
  <Loader />
  </div>
  </div>
: <div className="marmasco-section">
{error
? <div className="marmasco-container">
  <Error />
  </div>
: <>
<div className="marmasco-container marmasco-content marmasco-justify">
<h4 className="marmasco-text-teal"><b>WEB HOSTING<i className="fas fa-circle-info marmasco-right"></i></b></h4>
<p>Web hosting is a service that Marmasco provides to its customers to enable accessibility of their websites over World Wide Web. We power both static and dynamic webistes through our servers. You can host CMS like WordPress on our servers and experience amazing perfomance.</p>
<p>You can host your website on our shared platform. This is a very cheap and effective way to jump-start your business.</p>
<p>As your business grows, your needs may out grow our shared hosting packages, hence we give you a VPS dedicated to your website alone with a dedicated IP address and your own customised software. Qualified partners can resale our hosting services and all domain extensions.</p>

</div>
<br />
<div style={{
      backgroundImage: 'url(' + Bg + ')', backgroundRepeat: "no-repeat", backgroundPosition: "center center", backgroundSize: "100% 100%"
    }}>
<div className="marmasco-padding" style={{backgroundColor: "#ffffffe3"}}>
<div className="marmasco-container marmasco-content" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
<h4 className="marmasco-text-teal"><b><Link to="/get-domains/" style={{textDecoration: "none"}}>WOULD YOU LIKE TO GROW YOUR BUSINESS?</Link><Link to="/get-domains/" style={{textDecoration: "none"}}><i className="fas fa-globe marmasco-right"></i></Link></b></h4>
<div style={{ paddingTop: "30px" }}></div>
  <SearchDomain />
</div>
</div>
</div>
<div className="marmasco-container marmasco-content" >
  <div style={{paddingTop: "20px"}}></div>
  <ReadMore />
  </div>
  </>
}
  </div>
}
<Top />
</div>
);}

export default WebHosting;