import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import Loader from '../components/loader';
import Error from '../components/error';
import API from '../../jsx/backend';
import Top from '../hooks/topping';
import SearchDomain from '../components/searchDomain';
import ReadMore from '../components/readMore';
import { Link } from 'react-router-dom';
import Bg from '../../img/bg.jpg';

function DomainsFeatures() {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState('Please Wait');

    useEffect(() => {
        API.get(`api/pages/page/get-domains/features/`)
            .then((res) => {
                setTitle(res.data.title);
                setLoading(false);
            })
            .catch((error) => {
                setTitle('Not Responding');
                setLoading(false);
                setError(true);
            });
    }, []);

    return (
        <div className="marmasco-main put-margin inner">
            <Helmet>
                <title>{title} - Marmasco</title>
            </Helmet>

            {loading
                ? <div className="marmasco-section">
                    <div className="marmasco-container">
                        <Loader />
                    </div>
                </div>
                : <div className="marmasco-section">
                    {error
                        ? <div className="marmasco-container">
                            <Error />
                        </div>
                        : <>
                            <div className="marmasco-container marmasco-content marmasco-justify marmasco-padding">
                            <h4 className="marmasco-text-teal"><b>DOMAIN FEATURES<i className="fas fa-magic marmasco-right"></i></b></h4>
<p>Domain registration in Zimbabwe used to be a very tedius process. Marmasco managed to solve this problem through our automation system. All changes you make to your domain go straight to their respective registrars, for example, updates made to a .co.zw domain go straight to ZISPA.</p>
<p>Marmasco allows domain parking for all domain extensions, that is, both local and international domain names. Most registries, like  ZISPA require authoritative nameservers during the registration process. We ensure that you can secure your domain name with our free DNS service at zero cost. Domains registered through Marmasco can be parked, or be managed entirely from our Free DNS Service.</p>                          
<p>Most people wonder on how to start a business in domain registration industry. Marmasco has already solved this matrix through our domain regisration reseller service. We provide a free white label API for domain registration in Zimbabwe and other countries. Our domain registration reseller program can be integrated with any system through our ready made modules or through your custom code. Marmasco provides a comprehensive documentation on domain registartion API which is available through our blog.</p>
<p>Yes, we offer automation, free DNS Service and Free Reseller API, but that is not all, we also have very competitive prices. We are flexible for both start-ups and established businesses budgets.</p>

                         </div>
                         <br />
<div style={{
      backgroundImage: 'url(' + Bg + ')', backgroundRepeat: "no-repeat", backgroundPosition: "center center", backgroundSize: "100% 100%"
    }}>
<div className="marmasco-padding" style={{backgroundColor: "#ffffffe3"}}>
<div className="marmasco-container marmasco-content" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
<h4 className="marmasco-text-teal"><b><Link to="/get-domains/" style={{textDecoration: "none"}}>WOULD YOU LIKE TO GROW YOUR BUSINESS?</Link><Link to="/get-domains/" style={{textDecoration: "none"}}><i className="fas fa-globe marmasco-right"></i></Link></b></h4>
<div style={{ paddingTop: "30px" }}></div>
  <SearchDomain />
</div>
</div>
</div>
<div className="marmasco-container marmasco-content" >
  <div style={{paddingTop: "20px"}}></div>
  <ReadMore />
  </div>
                            
                        </>}
                </div>}
            <Top />
        </div>
    );
}

export default DomainsFeatures;