import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import Loader from '../components/loader';
import Error from '../components/error';
import API from '../../jsx/backend';
import Top from '../hooks/topping';

const Terms = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState('Please Wait');
  
  useEffect(() => {
  API.get(`api/pages/page/terms/`)
  .then((res) => {
  setTitle(res.data.title);
  setLoading(false);
  })
  .catch((error) => {
  setTitle('Not Responding');
  setLoading(false);
  setError(true);
  })}, []);
  
  return (
  <div className="marmasco-main put-margin inner">
  <Helmet>
  <title>{ title } - Marmasco</title>
  </Helmet>

{loading
? <div className="marmasco-section">
  <div className="marmasco-container">
  <Loader />
  </div>
  </div>
: <div className="marmasco-section">
{error
? <div className="marmasco-container">
  <Error />
  </div>
: <div className="marmasco-container marmasco-content marmasco-justify">

    
</div>
}
  </div>
}
<Top />
</div>
);}

export default Terms;