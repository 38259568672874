import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../img/logo.png";

function Header() {
  const [showWeb, setShowWeb] = useState(true);
  const [showEmail, setShowEmail] = useState(true);
  const [showVps, setShowVps] = useState(true);
  const [showDomain, setShowDomain] = useState(true);
  const [showReselling, setShowReselling] = useState(true);
  const [showRemoteComputer, setShowRemoteComputer] = useState(true);

  const handleWeb = () => {
    setShowWeb(!showWeb);
  };

  const handleRemoteComputer = () => {
    setShowRemoteComputer(!showRemoteComputer);
  };

  const handleEmail = () => {
    setShowEmail(!showEmail);
  };

  const handleVps = () => {
    setShowVps(!showVps);
  };

  const handleDomain = () => {
    setShowDomain(!showDomain);
  };

  const handleReselling = () => {
    setShowReselling(!showReselling);
  };

  function marmasco_open(e) {
    e.preventDefault();
    document.getElementById("mySidebar").style.display = "block";
    document.getElementById("myOverlay").style.display = "block";
  }

  function marmasco_close(e) {
    e.preventDefault();
    document.getElementById("mySidebar").style.display = "none";
    document.getElementById("myOverlay").style.display = "none";
  }

  return (
    <>
      <nav className="marmasco-scrollinfo marmasco-sidebar marmasco-bar-block marmasco-collapse marmasco-animate-left marmasco-card nav-index" id="mySidebar">

        <span onClick={marmasco_close} className="marmasco-bar-item marmasco-button marmasco-border-bottom level-top head-fix marmasco-white">
          <Link to="/"><img className="marmasco-large logo" src={Logo} alt="" /></Link>
        </span>

        <div className="nav-item-header" onClick={handleDomain}>
          <div><i className="fa fa-link"></i> GET DOMAINS</div>
          <div className="nav-item-sign">{showDomain ? <i className="fa fa-chevron-down marmasco-text-grey"></i> : <i className="fa fa-plus marmasco-text-grey"></i>}</div>
        </div>
        {showDomain && (
          <div className="accordian-body">
            <div onClick={marmasco_close}>
              <Link to="/get-domains/" className="marmasco-bar-item marmasco-button marmasco-small"><i className="fas fa-info-circle marmasco-text-grey"></i> Overview</Link>
              <Link to="/get-domains/features/" className="marmasco-bar-item marmasco-button marmasco-small"><i className="fas fa-magic marmasco-text-grey"></i> Features</Link>
              <Link to="/get-domains/pricing/" className="marmasco-bar-item marmasco-button marmasco-small"><i className="fas fa-tag marmasco-text-grey"></i> All Prices</Link>
            </div>

            <hr />
          </div>
        )}

        <div className="nav-item-header" onClick={handleWeb}>
          <div><i className="fa fa-globe"></i> WEB HOSTING</div>
          <div className="nav-item-sign">{showWeb ? <i className="fa fa-chevron-down marmasco-text-grey"></i> : <i className="fa fa-plus marmasco-text-grey"></i>}</div>
        </div>
        {showWeb && (
          <div className="accordian-body">
            <div onClick={marmasco_close}>
              <Link to="/web-hosting/" className="marmasco-bar-item marmasco-button marmasco-small"><i className="fas fa-info-circle marmasco-text-grey"></i> Overview</Link>
              <Link to="/web-hosting/features/" className="marmasco-bar-item marmasco-button marmasco-small"><i className="fas fa-magic marmasco-text-grey"></i> Features</Link>
              <Link to="/web-hosting/pricing/" className="marmasco-bar-item marmasco-button marmasco-small"><i className="fas fa-tag marmasco-text-grey"></i> All Prices</Link>
            </div>

            <hr />
          </div>
        )}

        <div className="nav-item-header" onClick={handleEmail}>
          <div><i className="fa fa-mail-bulk"></i> EMAIL HOSTING</div>
          <div className="nav-item-sign">{showEmail ? <i className="fa fa-chevron-down marmasco-text-grey"></i> : <i className="fa fa-plus marmasco-text-grey"></i>}</div>
        </div>
        {showEmail && (
          <div className="accordian-body">
            <div onClick={marmasco_close}>
              <Link to="/email-hosting/" className="marmasco-bar-item marmasco-button marmasco-small"><i className="fas fa-info-circle marmasco-text-grey"></i> Overview</Link>
              <Link to="/email-hosting/features/" className="marmasco-bar-item marmasco-button marmasco-small"><i className="fas fa-magic marmasco-text-grey"></i> Features</Link>
              <Link to="/email-hosting/pricing/" className="marmasco-bar-item marmasco-button marmasco-small"><i className="fas fa-tag marmasco-text-grey"></i> All Prices</Link>
            </div>

            <hr />
          </div>
        )}

        <div className="nav-item-header" onClick={handleVps}>
          <div><i className="fa fa-server"></i> VPS HOSTING</div>
          <div className="nav-item-sign">{showVps ? <i className="fa fa-chevron-down marmasco-text-grey"></i> : <i className="fa fa-plus marmasco-text-grey"></i>}</div>
        </div>
        {showVps && (
          <div className="accordian-body">
            <div onClick={marmasco_close}>
              <Link to="/vps-hosting/" className="marmasco-bar-item marmasco-button marmasco-small"><i className="fas fa-info-circle marmasco-text-grey"></i> Overview</Link>
              <Link to="/vps-hosting/features/" className="marmasco-bar-item marmasco-button marmasco-small"><i className="fas fa-magic marmasco-text-grey"></i> Features</Link>
              <Link to="/vps-hosting/pricing/" className="marmasco-bar-item marmasco-button marmasco-small"><i className="fas fa-tag marmasco-text-grey"></i> All Prices</Link>
            </div>

            <hr />
          </div>
        )}

        
<div className="nav-item-header" onClick={handleRemoteComputer}>
          <div><i className="fa fa-laptop"></i> REMOTE PC</div>
          <div className="nav-item-sign">{showRemoteComputer ? <i className="fa fa-chevron-down marmasco-text-grey"></i> : <i className="fa fa-plus marmasco-text-grey"></i>}</div>
        </div>
        {showRemoteComputer && (
          <div className="accordian-body">
            <div onClick={marmasco_close}>
              <Link to="/remote-computer/" className="marmasco-bar-item marmasco-button marmasco-small"><i className="fas fa-info-circle marmasco-text-grey"></i> Overview</Link>
              <Link to="/remote-computer/features/" className="marmasco-bar-item marmasco-button marmasco-small"><i className="fas fa-magic marmasco-text-grey"></i> Features</Link>
              <Link to="/remote-computer/pricing/" className="marmasco-bar-item marmasco-button marmasco-small"><i className="fas fa-tag marmasco-text-grey"></i> All Prices</Link>
            </div>

            <hr />
          </div>
        )}

        <div className="nav-item-header" onClick={handleReselling}>
          <div><i className="fa fa-briefcase"></i> RESELLING</div>
          <div className="nav-item-sign">{showReselling ? <i className="fa fa-chevron-down marmasco-text-grey"></i> : <i className="fa fa-plus marmasco-text-grey"></i>}</div>
        </div>
        {showReselling && (
          <div className="accordian-body">
            <div onClick={marmasco_close}>
              <Link to="/reseller-hosting/" className="marmasco-bar-item marmasco-button marmasco-small"><i className="fas fa-info-circle marmasco-text-grey"></i> Overview</Link>
              <Link to="/reseller-hosting/features/" className="marmasco-bar-item marmasco-button marmasco-small"><i className="fas fa-magic marmasco-text-grey"></i> Features</Link>
              <Link to="/reseller-hosting/pricing/" className="marmasco-bar-item marmasco-button marmasco-small"><i className="fas fa-tag marmasco-text-grey"></i> All Prices</Link>
            </div>

            <hr />
          </div>
        )}
        <br />
        <div>
        </div>
        <span className="marmasco-bar-item level-top nav-fix">
          <p onClick={marmasco_close}><Link to="/auth/login/" style={{ textDecoration: "none" }}><i className="fa fa-user-cog"></i> MY ACCOUNT <i className="fa fa-sign-in-alt marmasco-right marmasco-xlarge marmasco-text-teal"></i></Link></p>
        </span>
      </nav>
      <div className="marmasco-overlay marmasco-hide-large marmasco-animate-opacity pointer" onClick={marmasco_close} id="myOverlay"></div>
      <header className="marmasco-main put-margin head-fix">
        <div className="marmasco-container marmasco-sand marmasco-border-bottom level-top">
          <ul className="marmasco-content">
            <li className="marmasco-hide-large"><Link to="/"><item><img className="logo-small" src={Logo} alt="Marmasco" /></item></Link></li>
            <li><Link to="/" style={{ textDecoration: "none" }}><span><i className="fa fa-home marmasco-small"></i> <b className="marmasco-hide-small marmasco-hide-medium" style={{ fontWeight: "200" }}>Home</b></span></Link></li>
            <li><Link to="/blog/" style={{ textDecoration: "none" }}><span><i className="fa fa-blog marmasco-small"></i> <b className="marmasco-hide-small marmasco-hide-medium" style={{ fontWeight: "200" }}>Blog</b></span></Link></li>
            <li><Link to="/cart/" style={{ textDecoration: "none" }}><span><i className="fa fa-shopping-basket marmasco-small"></i><i className="marmasco-badge marmasco-red marmasco-tiny marmasco-right">0</i></span></Link></li>

            <li className="marmasco-hide-large" style={{ float: "right" }}><span onClick={marmasco_open}><i className="fa fa-bars marmasco-small"></i></span></li>
            <li style={{ float: "right" }}><Link to="/auth/login/" style={{ textDecoration: "none" }}><item> <b className="marmasco-hide-small marmasco-hide-medium" style={{ fontWeight: "200" }}>LOGIN</b> <i className="fa fa-sign-in-alt marmasco-small" style={{ colorHover: "white" }}></i></item></Link></li>
          </ul></div>
      </header>
    </>
  );
}

export default Header;