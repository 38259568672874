import React, { useState } from 'react';
import Loader from '../components/loader';
import API from '../../jsx/backend';
import { Link } from "react-router-dom";

const SignUp = () => {
  const [loadingReg, setLoadingReg] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  
  const handleRegister = (e) => {
  e.preventDefault();
  setLoadingReg(true);
  const registerFormDataNew = new FormData();
  registerFormDataNew.append("email",email);
  registerFormDataNew.append("name",name);
  
  API.post(`api/account/register/`, registerFormDataNew)
  .then((response) => {
  
  //sessionStorage.setItem("token", response.data.token);
  setLoadingReg(false);
  })
  .catch((error) => {
  
  setLoadingReg(false);
  })}
  
  return(
<div className="marmasco-content put-center">
    <div className="marmasco-padding put-center-data marmasco-white marmasco-card">
    <form onSubmit={handleRegister}>
    <div className="marmasco-container">
    <h4>REGISTER<i className="fas fa-user-plus marmasco-right"></i></h4>
    <br />
    {loadingReg
  ? <Loader />
  : <>
    <label for="name" className="marmasco-left">FULL NAME</label>
    <input type="text" id="name" className="marmasco-input marmasco-border" style={{width: "100%", borderRadius: "10px" }} value={name} onChange={(e) => setName(e.target.value)} placeholder="Your full name..." required />
    <br />
    <label for="email" className="marmasco-left">EMAIL ADDRESS</label>
    <input type="email" id="email" className="marmasco-input marmasco-border" style={{width: "100%", borderRadius: "10px" }} value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Your valid email address..." required />
     </>
     }
    <br />
    <input type="submit" className="marmasco-input marmasco-button marmasco-teal marmasco-border-0" style={{width: "100%", borderRadius: "10px" }} value="REGISTER NOW" />
    <br />
    </div>
    <div className="marmasco-container">
    <div className="marmasco-content">
    <div className="marmasco-half">
    <div className="marmasco-padding">
    <Link to="/auth/login/"><button type="button" className="marmasco-tiny marmasco-button marmasco-pale-green" style={{width: "100%" }}>LOGIN HERE</button></Link>
    </div>
    </div>
    <div className="marmasco-half">
    <div className="marmasco-padding">
    <Link to="/auth/reset/"><button type="button" className="marmasco-tiny marmasco-button marmasco-sand" style={{width: "100%" }}>SET PASSWORD</button></Link>
    </div>
    </div>
    </div>
    </div>
    </form>
    </div>
    </div>
  );}

export default SignUp;