import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import API from '../../jsx/backend';

function ReadMore() {
    const [tip, setTips] = useState('');

useEffect(() => {
        API.get(`api/tips/posts/blog/`)
        .then((res) => {
        setTips(res.data.tip);
        })
        .catch((error) => {
        setTips("");
        })}, []);

return (
<div className="marmasco-row-padding" style={{paddingBottom: "50px"}}>
<h4 className="marmasco-text-teal"><b><Link to="/blog/" style={{textDecoration: "none"}}>READ MORE</Link><Link to="/blog/" style={{textDecoration: "none"}}><i className="fas fa-blog marmasco-right"></i></Link></b></h4>

{tip
? <>
{tip.map((post) => (
<div className="marmasco-third" style={{borderRadius: "10px 10px 0 0"}}>
 <br />   
<Link to={`/blog/${post.url }/`} style={{textDecoration: "none"}} title={post.description }>
<div className="marmasco-card" style={{
      backgroundImage: 'url(' + post.image + ')',
      height: "auto", backgroundRepeat: "no-repeat", backgroundPosition: "center center", backgroundSize: "100% auto", borderRadius: "10px 10px 0 0"
    }}>
<div className="marmasco-padding" style={{backgroundColor: "#ffffffe3 ", minHeight: "350px", borderRadius: "10px"}}>
<h3 className="marmasco-center marmasco-text-grey">{ post.title  }</h3>
</div>
<button className="marmasco-btn marmasco-teal" style={{width: "100%"}}>READ MORE</button>
</div>
</Link>
</div>
))}
</>
: <h4 className="marmasco-center" style={{paddingTop: "50px"}}>We have no post suggestions!</h4>
}
</div>
);}

export default ReadMore;