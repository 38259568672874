import React, { useState } from 'react';
import Loader from './loader';
import API from '../../jsx/backend';
import { Link } from 'react-router-dom';
import InputHints from 'react-input-hints';


function SearchDomain() {
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [results, setResults] = useState(false);
    const [domain, setDomain] = useState('');
    const [extension, setExtension] = useState('');
    const [result, setResult] = useState('');
    const [status, setStatus] = useState('');
    const [price, setPrice] = useState('');
    const [currency, setCurrency] = useState('');
    const [available, setAvailable] = useState('');
    const [proceed, setProceed] = useState('');

    const forDomain = e => {
    setDomain(e.target.value);
    setResults(false);
  }
  const forExtension = e => {
    setExtension(e.target.value);
    setResults(false);
  }
    const handleSearch = (event) => {
        event.preventDefault();
        setProcessing(true);
        setResults(false);
        setError(false);
        const searchFormDataNew = new FormData();
        searchFormDataNew.append("domain", domain);
        searchFormDataNew.append("extension", extension);
        API.post(`api/domains/search/`, searchFormDataNew)
        .then((response) => {
        setResult(response.data.domain);
        setStatus(response.data.status);
        setPrice(response.data.price);
        setCurrency(response.data.currency);
        setAvailable(response.data.available);
        setProceed(response.data.proceed);
        setProcessing(false);
        setResults(true);
        })
        .catch((error) => {
        setError(true);
        setProcessing(false);
        })}
        
return (
<div className="marmasco-section marmasco-center">
<form className="marmasco-container marmasco-white marmasco-padding marmasco-border marmasco-border-grey" style={{borderRadius: "10px"}}  onSubmit={handleSearch}>
<div className="marmasco-col s2">
<span className="marmasco-input marmasco-border-0 marmasco-right">www.</span>
</div>
<div className="marmasco-col s4">
<InputHints type="text" value={domain} onChange={forDomain} pattern="[a-zA-Z0-9-]{2,63}$" className="marmasco-input marmasco-border-0" style={{borderRadius: "10px 0 0 10px", width: "100%", outline: "none"}} placeholders={[
        'type choice...',
        'domain',
        'company',
        'anything'
    ]}  required/>
</div>
<div className="marmasco-col s1">
<span className="marmasco-input marmasco-border-0 marmasco-right">.</span>
</div>
<div className="marmasco-col s2">
<InputHints type="text" value={extension} onChange={forExtension} pattern="[a-z.]{2,16}$" className="marmasco-input marmasco-border-0" style={{width: "100%", outline: "none"}} placeholders={[
        'type...',
        'co.zw',
        'com',
        'co.za',
        'etc',
    ]}  required/>
</div>
<div className="marmasco-col s3">
<button type="submit" className="marmasco-btn marmasco-teal marmasco-border-0" style={{borderRadius: "10px", width: "100%"}}><i className="fas fa-globe"></i> <span className="marmasco-hide-small">CHECK DOMAIN</span></button>
</div>
</form>
{results
? <>
{error
? <>
<p>Sorry, there was an error processing your request. Please try again later!</p>
</>
: <>
{status === "Supported Domain"
? <>
{proceed === "yes"
? <>
{available === "yes"
? <>
<p><span className="marmasco-text-teal  ">Congratulations!</span> { result } is available for { price } { currency } <Link to={`/cart/register/${ result }/`} style={{textDecoration: "none", borderRadius: "10px"}} className="marmasco-btn marmasco-teal">REGISTER NOW</Link></p>
</>
: <>
<p><span className="marmasco-text-brown">Sorry!</span> { result } is taken. Transfer to us for { price } { currency } <Link to={`/cart/transfer/${ result }/`} style={{textDecoration: "none", borderRadius: "10px"}} className="marmasco-btn marmasco-brown">TRANSFER NOW</Link></p>
</>
}
</>
:<>
<p>Sorry, we could not process your request. Please try a new search or try again later.</p>
</>
}
</>
: <p>{ status}</p>
}
</>
}
</>
: <>
{processing
? <Loader />
: <>
{domain && extension
?<p>You are about to submit a new search for <b>{ domain }.{ extension }</b>.</p>
:<p>Start looking for your domain, www. and extension preceeding dot are not allowed in the input form.</p>
}
</>
}
</>
}
</div>
);}

export default SearchDomain;