import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import Loader from '../components/loader';
import Error from '../components/error';
import API from '../../jsx/backend';
import Top from '../hooks/topping';
import SearchDomain from '../components/searchDomain';
import ReadMore from '../components/readMore';
import { Link } from 'react-router-dom';
import Bg from '../../img/bg.jpg';

const GetDomains = () => {
const [error, setError] = useState(null);
const [loading, setLoading] = useState(true);
const [title, setTitle] = useState('Please Wait');

useEffect(() => {
API.get(`api/pages/page/get-domains/`)
.then((res) => {
setTitle(res.data.title);
setLoading(false);
})
.catch((error) => {
setTitle('Not Responding');
setLoading(false);
setError(true);
})}, []);

return (
<div className="marmasco-main put-margin inner">
<Helmet>
<title>{ title } - Marmasco</title>
</Helmet>

{loading
? <div className="marmasco-section">
  <div className="marmasco-container">
  <Loader />
  </div>
  </div>
: <div className="marmasco-section">
{error
? <div className="marmasco-container">
  <Error />
  </div>
: <>
<div className="marmasco-container marmasco-content marmasco-justify">
<h4 className="marmasco-text-teal"><b>DOMAIN REGISTRATION<i className="fas fa-circle-info marmasco-right"></i></b></h4>
<p>A domain name is your identity and a special address on the internet where your website and emails can be reached. A valid domain name has an identifier and an extension, that is domainname.tld where domain is an identifier and tld is an extension. You can register hundreds of domain extensions through Marmasco.</p>
<p>When registering or transferring domains in Zimbabwe all details must belong to the actual owner otherwise domains registered with false information will be deleted.</p>
<p>All domains, either local or international extensions, registered through Marmasco must have valid nameservers.</p>

</div>
<br />
<div style={{
      backgroundImage: 'url(' + Bg + ')', backgroundRepeat: "no-repeat", backgroundPosition: "center center", backgroundSize: "100% 100%"
    }}>
<div className="marmasco-padding" style={{backgroundColor: "#ffffffe3"}}>
<div className="marmasco-container marmasco-content" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
<h4 className="marmasco-text-teal"><b><Link to="/get-domains/" style={{textDecoration: "none"}}>WOULD YOU LIKE TO GROW YOUR BUSINESS?</Link><Link to="/get-domains/" style={{textDecoration: "none"}}><i className="fas fa-globe marmasco-right"></i></Link></b></h4>
<div style={{ paddingTop: "30px" }}></div>
  <SearchDomain />
</div>
</div>
</div>
<div className="marmasco-container marmasco-content" >
  <div style={{paddingTop: "20px"}}></div>
  <ReadMore />
  </div>
  </>
}
  </div>
}
<Top />
</div>
);}

export default GetDomains;